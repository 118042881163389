import { Button } from 'antd';
import React, { useEffect, useState } from 'react';
import SwipeableViews from 'react-swipeable-views';
import styled from 'styled-components';
import logo from '../../images/logo.png';
import r_and_l from '../../images/r_and_l.png';
import full_blast_creative from '../../images/full_blast_creative.png';
import jenny from '../../images/jenny.png';
import { autoPlay } from 'react-swipeable-views-utils';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const Root = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  position: relative;
  padding: 0 12px 12px 12px;
  transition: all 0.5s;
  max-width: 100%;
  @media (max-width: 578px) {
    flex-direction: column;
    padding: 12px 0;
  }
`

const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  height: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  @media (max-width: 578px) {
    margin-top: 24;
    position: relative;
    width: 100%;
    justify-content: center;
    height: 48px;
  }
`

const NavButtonLeft = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.5s;
  padding-left: 36px;
  @media (max-width: 575px) {
    justify-content: flex-end;
    align-items: flex-end;
    margin-right: 12px;
  }
`;

const NavButtonRight = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.5s;
  padding-right: 36px;
  @media (max-width: 578px) {
    justify-content: flex-end;
    align-items: flex-end;
    margin-left: 12px;
  }
`;

const CardWrapper = styled.div`
  position: relative;
  width: 100%;
  max-width: 960px;
  transition: all 0.5s;
  padding: 24px;
`;

const Card = styled.div`
  width: 100%;
  max-width: 960px;
  margin: 0 auto;
  background-color: white;
  padding: 24px 64px 36px;
  border-radius: 16px;
  box-shadow: 8px 8px 16px rgba(0,0,0, 0.2);
  overflow: hidden;
  transition: all 0.5s;
  @media (max-width: 575px) {
		padding: 12px 12px 18px;
  }
`

const CardInner = styled.div`
  transition: all 0.5s;
  width: 100%;
  padding: 12px;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const CardDescription = styled.p`
  text-align: center;
`

const ImageContainer = styled.div`
  border-radius: 100%;
  overflow: hidden;
  padding: 16px;
  width: 72px;
  height: 72px;
  margin-bottom: 16px;
  box-shadow: 4px 4px 8px rgba(47,1,9, 0.17);
  display: flex;
  align-items: center;
  justify-content: center;
`

const Image = styled.img`
  width: 42px;
  object-fit: contain;
`

const Name = styled.p`
  margin-bottom: 16px;
  font-weight: bold;
  font-size: 15px;
  text-align: center;
`;

const From = styled.span`
  font-weight: normal;
  font-size: 13px;
`;

interface Props { };

const steps = [
  {
    name: "Jenny",
    from: " from Calgary",
    description: "DeReader is amazing! I am in my 3rd year of university and I use DeReader to share notes with my classmates. Last semester I lost my notebook for Biology but luckily, I had it backed up on DeReader. Truly a lifesaver! Since then, I have uploaded all my notes to the app and told my classmates to do the same. If one of us misses a lecture we simply send them the DeReader Code. It’s easy, simple to use and has made finals week so much less stressful.”",
    description2: "",
    image: jenny
  },
  {
    name: "R & L Contracting Ltd.",
    from: "",
    description: "To the creators of the DeReader app, just wanted to say how simple and easy you made this app to use. I use it for tracking all my company truck services and repairs. Now when I sell the vehicle, I will have all the information in one spot for the next owner.Thanks Guys!!!",
    description2: "",
    image: r_and_l
  },
  {
    name: "Carol",
    from: " from Red Deer",
    description: "Firstly, I have to say I am not the most tech-savvy person in the world. But my nephew introduced me to DeReader and it is so simple to use! I have been creating family albums and sharing them with my relatives overseas. We send pictures over DeReader and I create and share albums using the app. I have learnt so much about my family and our history. Even though they are miles away I can still connect with them in an instant! It’s so heartwarming to know we have these memories between us forever",
    description2: "",
    image: logo
  },
  {
    name: "Anonymous",
    from: "",
    description: "I have used this for all the important documents for my 1964 Ford restoration car. I have many original documents that I do not want destroyed so when we go to the car shows now it’s much easier to have the people scan my code for all information",
    description2: "",
    image: logo
  },
  {
    name: "Crystal DeCnodder",
    from: ", VP at Full Blast Creative",
    description: "Once our team went remote, I needed to track our computers, photo and video equipment with the team offsite. I can pull up the service history, tech specs and lease information at any time to track servicing and to maintain records for our insurance. It’s been nice having all of this paperwork ‘online’ and ready to share when needed. 10/10 would recommend. ",
    description2: "",
    image: full_blast_creative
  },
  {
    name: "Anonymous",
    from: "",
    description: "I have recently purchased a new vehicle and wanted to track my service and maintenance information. I was introduced to DeReader. I purchased the code, had it printed on a sticker and attached the sticker to my vehicle. Now whenever I have a service or maintenance I simply add the info into the code hit save and it is now updated. The amazing thing is I never have to change the code, just update the info inside it. ",
    description2: "Now when I sell my vehicle it is just a simple point and click of the app and all the info is displayed, as a read only, on my phone. I can also transfer the asset to the new owner securely and they now have control of the asset after accepting it. Thank you DeReader.ca. You are now my new document management service app.",
    image: logo
  },
]

const Testimonials: React.FC<Props> = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [height, setHeight] = useState(300);
  const numSteps = steps.length;
  useEffect(() => {
    if (typeof window !== 'undefined') {
      const card = document.getElementById(`active-${activeStep}`);
      console.log("🚀 ~ file: Testimonials.tsx ~ line 179 ~ useEffect ~ card", card)
      if (card) {
        setHeight(card.clientHeight + 36)
        console.log("🚀 ~ file: Testimonials.tsx ~ line 182 ~ useEffect ~ card.clientHeight + 24", card.clientHeight + 24)
      }
    }
  }, [activeStep]);
  const handleNext = () => {
    setActiveStep((prevActiveStep) => {
      if (prevActiveStep !== numSteps - 1) {
        return (prevActiveStep + 1)
      } else {
        return prevActiveStep
      }
    });
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => {
      if (prevActiveStep !== 0) {
        return prevActiveStep - 1
      } else {
        return prevActiveStep
      }
    });
  };
  const handleStepChange = (step: number) => {
    setActiveStep(step);
  };
  return (
    <Root>
      <CardWrapper>
        <Card style={{ height: height }}>
          <AutoPlaySwipeableViews
            index={activeStep}
            onChangeIndex={handleStepChange}
            enableMouseEvents
            springConfig={{ duration: '0.3s', easeFunction: 'ease-in', delay: '0s' }}
            interval={10000}
          >
            {
              steps.map((step, index) => {
                return (
                  <CardInner key={`active-${index}`} id={`active-${index}`}>
                    <ImageContainer style={{ padding: index === 0 ? 0 : undefined }}>
                      <Image style={{ width: index === 0 ? 72 : undefined }} src={step.image} />
                    </ImageContainer>
                    <Name>{step.name}<From>{step.from}</From></Name>
                    <CardDescription>{`"${step.description}`}{step.description2 ? `` : `"`}</CardDescription>
                    <CardDescription>{`${step.description2 ? `${step.description2}"` : ``}`}</CardDescription>
                  </CardInner>
                )
              })
            }
          </AutoPlaySwipeableViews>
        </Card>
        <ButtonsContainer>
          <NavButtonLeft style={{
            opacity: activeStep === 0 ? 0 : 1,
          }}>
            <Button type="primary" onClick={handleBack} shape="circle" icon="left" disabled={activeStep === 0} />
          </NavButtonLeft>
          <NavButtonRight style={{
            opacity: activeStep === numSteps - 1 ? 0 : 1,
          }}>
            <Button type="primary" onClick={handleNext} shape="circle" icon="right" disabled={activeStep === numSteps - 1} />
          </NavButtonRight>
        </ButtonsContainer>
      </CardWrapper>
    </Root>
  );
}

export default Testimonials;
import React, { useState, useEffect } from "react";
import {
  Banner,
  BannerBadge,
  BannerButton,
  BannerContents,
  BannerTextBox,
  BannerTitle,
  HomeBadge,
  PageContents,
  VideoBanner,
  VideoBannerOverlay,
  CenteredSectionSmall,
  CenteredHeader,
  CenteredText,
  HomeCard,
  SmallCardIcon,
  StoreIcon,
  StoreCol,
  HomeCardCol,
  CheckListItem,
  Bold,
  Checklist,
  ImageContainer,
  HomeImage,
  GradientCard,
  GradientCardText,
  GradientCardTitle,
  GradientCardIcon,
  GradientCardHeader,
  GradientCardDivider,
  GradientCardIconContainer,
  GradientCardsRow,
  CenteredSectionWithDots,
  GradientCardCol,
  DottedList,
  DottedListItem,
  FullWidthSection,
  CenteredHeaderWhite,
  NumberedList,
  NumberedListItem,
  StepRow,
  HomeDivider,
} from "./styled";
import { HomePageData } from "../../models/common";
import ReactPlayer from "react-player/lazy";
import VideoModal from "./VideoModal";
import { Button, InternalLinkButtonWrapper } from "../../theme/components";
import { PRIMARY_COLOR } from "../../theme/constants";
import { Col, Divider, Row } from "antd";
import Header from "../../layout/Header";
import WebIcon from "../../icons/web.svg";
import PlayIcon from "../../icons/play.svg";
import home_collaborate from "../../images/home_collaborate.png";
import home_customers from "../../images/home_customers.png";
import home_keep_track from "../../images/home_keep_track.png";
import home_monitor from "../../images/home_monitor.png";
import home_organize from "../../images/home_organize.png";
import home_download from "../../images/home_download.png";
import home_assign from "../../images/home_assign.png";
import home_attach from "../../images/home_attach.png";
import home_share from "../../images/home_share.png";
import app_store from "../../images/app_store.svg";
import google_play from "../../images/google_play.svg";
import phones from "../../images/phones.jpg";
import laptop from "../../images/laptop.png";
import home_truck from "../../images/home_truck.png";
import home_example_1 from "../../images/home_example_1.png";
import home_example_2 from "../../images/home_example_2.png";
import home_example_3 from "../../images/home_example_3.png";
import home_example_4 from "../../images/home_example_4.png";
import Testimonials from "./Testimonials";
import AppButtons from "./AppButtons";

interface Props {
  data: HomePageData;
}

const HomeRoot: React.FC<Props> = (props) => {
  const [visible, setVisible] = useState(false);
  const openVideo = () => {
    setVisible(true);
  };
  const closeVideo = () => {
    setVisible(false);
  };
  return (
    <React.Fragment>
      <VideoBanner className="video-wrapper">
        <ReactPlayer
          url={props.data.banner_video_short.file.url}
          width="100%"
          height="100vh"
          volume={0}
          muted={true}
          loop={true}
          playing={true}
          style={{ position: "absolute", zIndex: -2, backgroundColor: "black" }}
        />
        <VideoModal
          url={"https://youtu.be/Mex6Sqcf92o"}
          visible={visible}
          onClose={closeVideo}
        />
        <VideoBannerOverlay />
        <Header />
        <Banner>
          <BannerContents>
            <BannerTextBox>
              <BannerBadge>Scan. Share. Collaborate.</BannerBadge>
              <BannerTitle>
                Everything you need to{" "}
                <span style={{ color: PRIMARY_COLOR }}>
                  manage your business and personal assets
                </span>
                , in the palm of your hands.
              </BannerTitle>
              <div>
                <a
                  href="https://app.dereader.ca"
                  rel="noreferrer noopener"
                  target="_blank"
                >
                  <BannerButton color="secondary" size="default">
                    <WebIcon /> Get Started
                  </BannerButton>
                </a>
                <BannerButton
                  color="primary"
                  size="default"
                  onClick={openVideo}
                >
                  <PlayIcon /> Watch the Video
                </BannerButton>
              </div>
            </BannerTextBox>
          </BannerContents>
        </Banner>
      </VideoBanner>
      <PageContents>
        <CenteredSectionSmall>
          <HomeBadge>State-Of-The-Art Mobile and Desktop App</HomeBadge>
          <CenteredHeader>
            Introducing DeReader, a state-of-the-art mobile and desktop app that
            manages your assets anywhere
          </CenteredHeader>
          <Row
            type="flex"
            align="middle"
            justify="center"
            gutter={24}
            style={{ maxWidth: 860, marginTop: 24 }}
          >
            <Col md={8} sm={8} xs={24}>
              <HomeCardCol>
                <HomeCard>
                  <SmallCardIcon
                    src={home_keep_track}
                    alt="Keep Track of your assets"
                  />
                  <p>
                    Keep track of receipts and documentation (insurance, vehicle
                    and renovations)
                  </p>
                </HomeCard>
              </HomeCardCol>
            </Col>
            <Col md={8} sm={8} xs={24}>
              <HomeCardCol>
                <HomeCard>
                  <SmallCardIcon
                    src={home_organize}
                    alt="Organize your assets"
                  />
                  <p>
                    Organize school projects; share it with classmates and
                    teachers
                  </p>
                </HomeCard>
              </HomeCardCol>
            </Col>
            <Col md={8} sm={8} xs={24}>
              <HomeCardCol>
                <HomeCard>
                  <SmallCardIcon
                    src={home_collaborate}
                    alt="Collaborate with anyone"
                  />
                  <p>
                    Collaborate with overseas clients, colleagues, businesses or
                    family
                  </p>
                </HomeCard>
              </HomeCardCol>
            </Col>
            <Col md={8} sm={8} xs={24}>
              <HomeCardCol>
                <HomeCard>
                  <SmallCardIcon
                    src={home_monitor}
                    alt="Share workplace documentation"
                  />
                  <p>
                    Monitor and share workplace documentation (inventory,
                    construction, travel receipts)
                  </p>
                </HomeCard>
              </HomeCardCol>
            </Col>
            <Col md={8} sm={8} xs={24}>
              <HomeCardCol>
                <HomeCard>
                  <SmallCardIcon
                    src={home_customers}
                    alt="Send information to customers"
                  />
                  <p>
                    Send customers valuable product or business information
                    (selling a car, menu at your restaurant)
                  </p>
                </HomeCard>
              </HomeCardCol>
            </Col>
            <Col md={8} sm={8} xs={24}>
              <HomeCardCol>
                <HomeCard style={{ borderColor: "#FF2A4C" }}>
                  <p>
                    Simply upload your information, link it to one of our
                    DeReader codes and start scanning.
                  </p>
                  <InternalLinkButtonWrapper
                    to={"/how-it-works"}
                    title="How it Works"
                  >
                    <Button color="primary" size="small">
                      Learn More
                    </Button>
                  </InternalLinkButtonWrapper>
                </HomeCard>
              </HomeCardCol>
            </Col>
          </Row>
          <CenteredText style={{ marginTop: 16 }}>
            Access your most important asset information on your phone or
            desktop, share it with anyone in the world in an instant. Rest easy
            knowing your information is securely with you anytime anywhere.
          </CenteredText>
          <AppButtons />
        </CenteredSectionSmall>
        <CenteredSectionSmall style={{ marginBottom: 48 }}>
          <Row gutter={16} type="flex" align="middle" justify="center">
            <Col md={12} sm={12} xs={24}>
              <ImageContainer>
                <HomeImage src={phones} alt="DeReader - Benefits" />
              </ImageContainer>
            </Col>
            <Col md={12} sm={12} xs={24}>
              <h2>Benefits</h2>
              <Checklist>
                <CheckListItem>
                  <Bold>Back up important paperwork,</Bold> information or data
                  from unforeseen accidents
                </CheckListItem>
                <CheckListItem>
                  <Bold>Secure and control</Bold> your assets
                </CheckListItem>
                <CheckListItem>
                  <Bold>Increase productivity</Bold> through virtually sharing
                  information with co-workers, team members and classmates
                </CheckListItem>
                <CheckListItem>
                  <Bold>Manage assets</Bold> from a unified platform
                </CheckListItem>
                <CheckListItem>
                  <Bold>See your assets,</Bold> and the ones you have scanned on
                  the go
                </CheckListItem>
                <CheckListItem>
                  <Bold>Increase customer satisfaction</Bold> through sharing
                  important service and/or product information anytime
                </CheckListItem>
                <CheckListItem>
                  <Bold>Remove geographic barriers</Bold> when connecting with
                  friends, family, businesses, or customers
                </CheckListItem>
              </Checklist>
              <InternalLinkButtonWrapper to="/connect" title="Connect">
                <Button size="large" color="primary" style={{ marginTop: 8 }}>
                  Connect to learn more
                </Button>
              </InternalLinkButtonWrapper>
            </Col>
          </Row>
        </CenteredSectionSmall>
        <CenteredSectionWithDots style={{ marginBottom: 48 }}>
          <HomeBadge>State-Of-The-Art Mobile and Desktop App</HomeBadge>
          <CenteredHeader>4 steps to get started</CenteredHeader>
          <GradientCardsRow
            type="flex"
            align="middle"
            justify="center"
            gutter={24}
          >
            <GradientCardCol md={6} sm={12} xs={24}>
              <HomeCardCol>
                <GradientCard>
                  <GradientCardHeader>
                    <GradientCardIconContainer>
                      <GradientCardIcon
                        src={home_download}
                        alt="Download and Sign Up"
                      />
                    </GradientCardIconContainer>
                    <GradientCardDivider />
                  </GradientCardHeader>
                  <GradientCardTitle>Download and Sign Up</GradientCardTitle>
                  <GradientCardText>
                    Download and sign up for the DeReader Asset Management App.
                  </GradientCardText>
                </GradientCard>
              </HomeCardCol>
            </GradientCardCol>
            <GradientCardCol md={6} sm={12} xs={24}>
              <HomeCardCol>
                <GradientCard>
                  <GradientCardHeader>
                    <GradientCardIconContainer>
                      <GradientCardIcon src={home_assign} alt="Assign Files" />
                    </GradientCardIconContainer>
                    <GradientCardDivider />
                  </GradientCardHeader>
                  <GradientCardTitle>Assign Files</GradientCardTitle>
                  <GradientCardText>{`Assign files & information to a custom DeReader Code within the App.`}</GradientCardText>{" "}
                </GradientCard>
              </HomeCardCol>
            </GradientCardCol>
            <GradientCardCol md={6} sm={12} xs={24}>
              <HomeCardCol>
                <GradientCard>
                  <GradientCardHeader>
                    <GradientCardIconContainer>
                      <GradientCardIcon
                        src={home_attach}
                        alt="Attach the code"
                      />
                    </GradientCardIconContainer>
                    <GradientCardDivider />
                  </GradientCardHeader>
                  <GradientCardTitle>Attach the code</GradientCardTitle>
                  <GradientCardText>
                    Attach the DeReader Code to your asset
                  </GradientCardText>
                </GradientCard>
              </HomeCardCol>
            </GradientCardCol>
            <GradientCardCol md={6} sm={12} xs={24}>
              <HomeCardCol>
                <GradientCard>
                  <GradientCardHeader>
                    <GradientCardIconContainer>
                      <GradientCardIcon src={home_share} alt={`Share & view`} />
                    </GradientCardIconContainer>
                    <GradientCardDivider />
                  </GradientCardHeader>
                  <GradientCardTitle>{`Share & view`}</GradientCardTitle>
                  <GradientCardText>
                    Scan, edit, share and view!
                  </GradientCardText>
                </GradientCard>
              </HomeCardCol>
            </GradientCardCol>
          </GradientCardsRow>
        </CenteredSectionWithDots>
        <CenteredSectionSmall style={{ marginBottom: 24 }}>
          <Row gutter={16} type="flex" align="middle" justify="center">
            <Col md={12} sm={12} xs={24}>
              <ImageContainer>
                <HomeImage src={laptop} alt="DeReader - Industries" />
              </ImageContainer>
            </Col>
            <Col md={12} sm={12} xs={24}>
              <HomeBadge>Industries Using DeReader App</HomeBadge>
              <h2>Industries benefiting from the DeReader App</h2>
              <Row>
                <Col md={12} sm={12} xs={12}>
                  <DottedList>
                    <DottedListItem>Inventory Management</DottedListItem>
                    <DottedListItem>Event Management</DottedListItem>
                    <DottedListItem>Oil and Gas</DottedListItem>
                    <DottedListItem>Advertising</DottedListItem>
                    <DottedListItem>Sales</DottedListItem>
                    <DottedListItem>Hospitals</DottedListItem>
                    <DottedListItem>Maintenance</DottedListItem>
                  </DottedList>
                </Col>
                <Col md={12} sm={12} xs={12}>
                  <DottedList>
                    <DottedListItem>Insurance</DottedListItem>
                    <DottedListItem>Construction</DottedListItem>
                    <DottedListItem>Supply Chain</DottedListItem>
                    <DottedListItem>Inspection</DottedListItem>
                    <DottedListItem>Education</DottedListItem>
                    <DottedListItem>Asset Tracking</DottedListItem>
                  </DottedList>
                </Col>
              </Row>
            </Col>
          </Row>
        </CenteredSectionSmall>
      </PageContents>
      <FullWidthSection>
        <HomeBadge>Testimonials</HomeBadge>
        <CenteredHeaderWhite>What our users have to say</CenteredHeaderWhite>
        <Testimonials />
      </FullWidthSection>
      <PageContents style={{ marginTop: 64 }}>
        <CenteredSectionSmall>
          <Row gutter={24} type="flex" align="middle" justify="center">
            <Col md={14} sm={14} xs={24}>
              <HomeBadge>You Want To Sell Your Car?</HomeBadge>
              <h2>Here’s an Easy Example of How the DeReader App Works</h2>
              <DottedList>
                <DottedListItem>
                  Download DeReader, and attach any important information to a
                  DeReader code provided through the app. Include the make,
                  model, colour and millage.
                </DottedListItem>
                <DottedListItem>
                  Share your code online or attach it on your car.
                </DottedListItem>
                <DottedListItem>
                  Customers can now scan the code and view your vehicle’s
                  information.
                </DottedListItem>
              </DottedList>
            </Col>
            <Col md={10} sm={10} xs={24}>
              <ImageContainer>
                <HomeImage src={home_truck} alt="DeReader - Sell your car" />
              </ImageContainer>
            </Col>
          </Row>
        </CenteredSectionSmall>
        <HomeDivider />
        <CenteredSectionSmall>
          <StepRow gutter={48} type="flex" align="middle" justify="center">
            <Col md={12} sm={12} xs={24}>
              <ImageContainer>
                <HomeImage
                  src={home_example_1}
                  alt="DeReader - Download and Sign Up"
                />
              </ImageContainer>
            </Col>
            <Col md={12} sm={12} xs={24}>
              <HomeBadge>Step One</HomeBadge>
              <h2>Download and Sign Up</h2>
              <NumberedList>
                <NumberedListItem number={"1"}>
                  Download DeReader from the App Store or Google Play
                </NumberedListItem>
                <NumberedListItem number={"2"}>
                  Sign up using your name, email and create a password
                </NumberedListItem>
              </NumberedList>
            </Col>
          </StepRow>

          <StepRow gutter={48} type="flex" align="top" justify="center">
            <Col md={12} sm={12} xs={24}>
              <ImageContainer>
                <HomeImage src={home_example_2} alt="DeReader - Assign files" />
              </ImageContainer>
            </Col>
            <Col md={12} sm={12} xs={24}>
              <HomeBadge>Step Two</HomeBadge>
              <h2>Assign files and information to a QR Code</h2>
              <NumberedList>
                <NumberedListItem number={"1"}>
                  Select a DeReader code
                </NumberedListItem>
                <NumberedListItem number={"2"}>
                  Pick a recipe: Pipeline or basic
                </NumberedListItem>
                <NumberedListItem number={"3"}>
                  Add information: Name and identify your asset
                </NumberedListItem>
                <NumberedListItem number={"4"}>
                  Add Identification: Name, identify, type of field and value
                </NumberedListItem>
                <NumberedListItem number={"5"}>
                  Add the location: Address, postal code or coordinates
                </NumberedListItem>
                <NumberedListItem number={"6"}>
                  Add the physical: What it looks like, name, units
                </NumberedListItem>
                <NumberedListItem number={"7"}>
                  Add any other important information to your asset
                </NumberedListItem>
                <NumberedListItem number={"8"}>
                  Attach files: Word Doc, Excel, or PDF
                </NumberedListItem>
                <NumberedListItem number={"9"}>
                  Save your asset
                </NumberedListItem>
              </NumberedList>
            </Col>
          </StepRow>

          <StepRow gutter={48} type="flex" align="middle" justify="center">
            <Col md={12} sm={12} xs={24}>
              <ImageContainer>
                <HomeImage
                  src={home_example_3}
                  alt="DeReader - Attach it to your asset"
                />
              </ImageContainer>
            </Col>
            <Col md={12} sm={12} xs={24}>
              <HomeBadge>Step Three</HomeBadge>
              <h2>Attach it to your asset</h2>
              <NumberedList>
                <NumberedListItem number={"1"}>
                  Generate a DeReader code and attach it to your asset
                </NumberedListItem>
                <NumberedListItem number={"2"}>
                  Print your DeReader code on stickers, plastic or metal. Get it
                  shipped from your local printer
                </NumberedListItem>
              </NumberedList>
            </Col>
          </StepRow>

          <StepRow gutter={48} type="flex" align="middle" justify="center">
            <Col md={12} sm={12} xs={24}>
              <ImageContainer>
                <HomeImage
                  style={{ maxHeight: 350 }}
                  src={home_example_4}
                  alt="DeReader - Scan and View"
                />
              </ImageContainer>
            </Col>
            <Col md={12} sm={12} xs={24}>
              <HomeBadge>Step Four</HomeBadge>
              <h2>Scan and View</h2>
              <NumberedList>
                <NumberedListItem number={"1"}>
                  Use DeReader to scan and view your asset's information
                </NumberedListItem>
                <NumberedListItem number={"2"}>
                  Share your code with others
                </NumberedListItem>
              </NumberedList>
            </Col>
          </StepRow>
        </CenteredSectionSmall>
        <CenteredSectionWithDots
          style={{
            paddingBottom: 112,
            backgroundPosition: "90% 95%",
          }}
        >
          <HomeBadge>Download The App</HomeBadge>
          <CenteredHeader style={{ maxWidth: 768, marginBottom: 0 }}>
            Everything you need to manage your assets, in the palm of your
            hands.
          </CenteredHeader>
          <AppButtons />
        </CenteredSectionWithDots>
      </PageContents>
    </React.Fragment>
  );
};

export default HomeRoot;

import React from 'react';
import { Seo } from '../components/Seo'
import Layout from '../layout/Layout';
import HomeRoot from '../views/Home/HomeRoot';
import { graphql } from 'gatsby';

const IndexPage = (props) => (
	<Layout>
		<Seo
			metaDescription={"DeReader is everything you need to manage your business and personal assets, in the palm of your hands."}
			title={"Home"}
			slug={'/'}
		/>
		<HomeRoot data={props.data} />
	</Layout>
);

export default IndexPage;

export const query = graphql`
	query HomePage {
		banner_video_short: contentfulAsset(title: { eq: "banner_video_short" }) {
			file {
				url
			}
		}
	}
`;
